import Swal from 'sweetalert2'
import i18n from '@/libs/i18n'

const _inputValue = ''

const _ToastVitalDelete = Swal.mixin({
  icon: 'warning',
  showCancelButton: true,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-dark ml-1',
  },
  buttonsStyling: false,
  input: 'text',
  inputValue: _inputValue,
})

const _ToastDelete = Swal.mixin({
  icon: 'warning',
  showCancelButton: true,
  
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-dark ml-1',
  },
  buttonsStyling: false,
})

const fireDeleteAlert = (vital, text) => {
  if (vital) {
    return new Promise((resolve, reject) => {
      resolve(
        _ToastVitalDelete
          .fire({ 
            text: text || i18n.global.t('alert.delete_confirmation.message') , 
            title: i18n.global.t('alert.delete_confirmation.title'), 
            confirmButtonText: i18n.global.t('alert.delete_confirmation.approve'),
            cancelButtonText: i18n.global.t('alert.delete_confirmation.cancel'),
            inputLabel: i18n.global.t('alert.delete_vital_confirmation.input_label'),
            inputValidator: value => {
              if (value.toLowerCase() !== 'delete') {
                return i18n.global.t('alert.delete_vital_confirmation.input_validator')
              }
            }
          }).then(result => result.isConfirmed),
      )
    })
  }
  return new Promise((resolve, reject) => {
    resolve(
      _ToastDelete
        .fire({ 
          text: text || i18n.global.t('alert.delete_confirmation.message'),
          title: i18n.global.t('alert.delete_confirmation.title'),
          confirmButtonText: i18n.global.t('alert.delete_confirmation.approve'),
          cancelButtonText: i18n.global.t('alert.delete_confirmation.cancel'),
        }).then(result => result.isConfirmed),
    )
  })
}

export { fireDeleteAlert }
